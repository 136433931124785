<template>
  <div>
    <ul class="menu-list">
      <li v-for="cat in categories" :key="'c_' + cat.Id">
        <span class="mainMenuItem">{{ cat.Name }}</span>
        <ul class="submenu-list">
          <li
            v-for="func in getCategoryFunctions(cat.Id)"
            :key="'f_' + func.Id"
          >
            <label class="checkbox">
              <input
                type="checkbox"
                @change="emitClick"
                :value="func.Id"
                v-model.lazy="selectedFunctionalities"
              />
              {{ func.Name }}
            </label>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  components: {},

  props: {},

  data() {
    return {
      selectedFunctionalities: [],
    }
  },

  computed: {
    ...mapState('bundleStore', ['bundle']),
    ...mapState('functionalityStore', ['categories', 'functionalities']),

    filteredCategies() {
      let categories = []

      return categories
    },
  },

  created() {
    if (this.bundle) {
      this.selectedFunctionalities = this.bundle.Functionalities.map(
        (f) => f.Id
      )
    }

    if (this.categories && this.categories.length === 0) {
      this.getAllCategories()
    }

    if (this.functionalities && this.functionalities.length === 0) {
      this.getAllFunctionalities()
    }
  },

  methods: {
    ...mapActions('functionalityStore', [
      'getAllCategories',
      'getAllFunctionalities',
    ]),

    getCategoryFunctions(categoryId) {
      return this.functionalities.filter((f) => f.CategoryId === categoryId)
    },

    emitClick() {
      this.$emit('functioniltyUpdate', this.selectedFunctionalities)
    },
  },
}
</script>

<style></style
><style lang="scss" scoped>
@import '@/assets/styles/template';
a {
  display: flex;
  align-items: center;
  user-select: none;
  span {
    &:first-child {
      flex-grow: 1;
    }
  }

  &.mainMenuItem {
    .icon {
      transition: 0.5s;
      animation-duration: 0.5s;
      transform: rotate(0deg);
    }
    &.is-visible-submenu {
      .icon {
        transform: rotate(90deg);
      }
    }
  }
}

.submenu-list {
  border-left-color: $blue;
}
</style>
